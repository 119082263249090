export const roDict = new Map<string, string>([
    //header menu
    ["home", "Acasă"],
    ["about", "Detalii"],
    ["services", "Servicii"],
    ["work", "Proiecte"],
    ["blog", "Blog"],
    ["contact", "Contact"],
    ["languageToolTip", "Schimbă pe engleză"],
    ["homeTitle", "Sunt Marian Salvan"],
    ["homeDescriptionDev", "Dezvoltator de aplicații web"],
    ["homeDescriptionEng", "Inginer Software"],
    ["homeButton", "Află mai multe"],

    //about
    ["aboutName", "Nume"],
    ["aboutProfie", "Profesie"],
    ["aboutJob", "Dezvoltator de aplicații web"],
    ["aboutCV", "Vezi CV-ul (engleză)"],
    ["aboutTech", "Câteva dintre tehnologiile cu care am lucrat"],
    ["aboutTitle", "Despre mine"],
    ["aboutDescriptionP1", `Sunt un dezvoltator de aplicații web cu 5+ ani experiență în acest domeniu și cu licență în 
                            Ingineria Calculatoarelor. Chiar dacă în principal lucrez de dezvoltator web, sunt familiar și cu 
                            domeniul Inteligenței Artificiale, având un master în acest subiect.`],
    ["aboutDescriptionP2", `Am lucrat la mai multe proiecte majore la câteva nume mari din industria mondială de IT cum ar fi
                            Micro Focus, Garmin sau GEP. Aria mea de expertiză include tot ciclul de dezvoltare a unei soluții web: 
                            partea de backend, intefețe de utilizator și baze de date. Sunt de asemenea familiar cu procesul de
                            integrare a aplicațiilor web în cloud.`],
    ["aboutDescriptionP3", `În ziua de astăzi putem spune că trăim în mijlocul unei revoluții tehnologice, iar acest lucru poate fi
                            copleșitor, din cauza multitudinilor de alternative existente, pentru cineva care are nevoie de o soluție web de calitate. 
                            Cred cu tărie că orice idee măreață are nevoie de un soluție tehnică la fel de măreață. De aceea, misiunea mea 
                            este să-i ajut pe oameni să-și dea seama de ceea ce au nevoie cu adevărat și să lucrez împreună cu
                            ei pentru dezvoltarea unor soluții eficiente și de ultimă generație.`],

    //services
    ["servicesDescription", "Servicile pe care le ofer"],

    ["consultingTitle", "Consultanță"],
    ["consultingDesciption", `Ofer suport tehnic clienților care doresc o soluție web pentru afacerea lor`],

    ["frontEndTitle", "Dezvoltare de soluții web noi"],
    ["frontEndDescription", "Dezvolt applicații web interactive de la 0 folosind tehnologii de ultimă generație pentru orice tip de afacere"],

    ["backEndTitle", "Îmbunățirea soluțiilor web existente"],
    ["backEndDescription", `Adaug funționalități noi la proiecte existente ale clienților`],

    ["responsiveDesignTitle", "Design adaptabil"],
    ["responsiveDesignDescription", `Dezvolt site-uri web care pot fi folosite de pe dispozitive și rezoluții multiple`],

    ["deploymentTitle", "Găzduire site-uri"],
    ["deploymentDescription", `Găzduiesc site-urile pe platforme cu costuri minimale`],

    ["mantainanceTitle", "Mentenanță"],
    ["mantainanceDescription", "Mențin și actualizez site-urile dezvoltate"],

    //work
    ["portofolioTitle", "Proiecte"],
    ["portofolioDescription", "Proiecte la care am lucrat"],
    ["projectDetailsTitle", "Detalii despre proiect"],

    //portofolio details
    ["detailsProjectInfo", "Informații desre proiect"],
    ["detailsProjectName", "Numele proiectului"],
    ["detailsCategory", "Categorie"],
    ["detailsCompany", "Organizație"],
    ["detailsProjectDescription", "Descrierea proiectului"],
    ["techsUsed", "Tehnologii folosite"],

    ["projectTitleLoadRunner", "LoadRunner Enterprise"],
    ["projectCategoryLoadRunner", "Proiect Companie"],
    ["projectDescriptionLoadRunner", `LoadRunner Enterprise este cel mai folosit produs de pe piață când vine vorba despre testarea performanței. 
                                      Este folosit de multe companii importante pentru îmnunătățirea perfomanței aplicațiilor lor. Pentru acest
                                      acest produs am lucrat la îmbunățirea interfeței și la numeroase integrări cu servicii de cloud. De asemenea, 
                                      am luat parte și la câteva decizii arhitecturale, precum și la efortul de a rescrie proiectul folosind microservicii`],
    ["projectStackLoadRunner", ".NET core, Angular, jQuery, Microsoft SQL, Entity Framework Core, AWS și Azure"],
    ["projectCompanyMf", "Micro Focus"],

    ["projectTitleInreach", "Comunicarea prin satelit a dispozitivelor inReach"],
    ["projectCategoryInreach", "Proiect Companie"],
    ["projectDescriptionInreach", `Dispozitivele inReach sunt dispozitive asemănătoare cu telefoanele mobile care comunică prin satelit. Ele pot să fie 
                                  folosite în zone nu există semnal pentru telefon, având funcționalități de primire și trimitere de SMS, hărți GPS sau
                                  declanșare de SOS în caz de urgențe. Am fost parte din echipa care a dezvoltat funționalitatea de SMS, implementând
                                  un microserviciu care făcea legătura între dispozitive și rețeaua de sateliți Iridium`],
    ["projectStackInreach", `NET Core, Service Fabric, Terraform sau Azure. De asemenea, am lucrat pentru o perioadă scurtă de timp și cu Blazor pentru
                             implementarea unei site intern de administrare`],
    ["projectCompanyGarmin", "Garmin"],


    ["projectTitleGepSmart", "GEP Smart"],
    ["projectCategoryGepSmart", "Proiect Companie"],
    ["projectDescriptionGepSmart", `GEP Smart este o platformă care adună la un loc toate operațiile logistice folostite de un lanț modern de aprovizionare de la
                                    sursă până la destinație. Platforma oferă posibilitea utilizatorilor să administreze toate documentele și contractele
                                    din cadrul procesului logistic. Am făcut parte din echipa de inginerie care a implementat o nouă iterație a platformei,
                                    bazată pe conceptul de Low Code, folosindu-ne de arhitectura cu micro servicii și micro interfețe de utilizator`],
    ["projectStackGepSmart", "Angular, .NET core, MongoDB, Cypress sau Azure"],
    ["projectCompanyGep", "GEP"],

    ["projectTitleCrr", "Aplicație de gestiune a donațiilor pentru Crucea Roșie Română (Filiala Cluj)"],
    ["projectCategoryCrr", "Proiect Personal"],
    ["projectDescriptionCrr", `M-am oferit voluntar să contruiesc această aplicație pentru Crucea Roșie Română Cluj cu scopul de a digitaliza procesul de 
                               gestiune a donațiilor. Inițial, aplicația a fost folosită doar pentru gestiunea donațiilor care urmau să plece spre Ucraina,
                               între timp fiind modificată pentru a fi folosită și în de alte scenarii. Ofer suport și mentenață periodică pentru 
                               această soluție`],
    ["projectStackCrr", "React + Redux, Boostrap și Firebase"],
    ["projectCompanyCrr", "Crucea Roșie Română (Filiala Cluj)"],

    //contacts
    ["contactFormTitle", "Trimite-mi un mesaj"],
    ["contactFormName", "Numele tău"],
    ["contactFormEmail", "Adresa ta de email"],
    ["contactFormSubject", "Subiect"],
    ["contactFormMessage", "Mesaj"],
    ["contactFormButton", "Trimite mesajul"],
    ["contactDetailsTitle", "Hai să luăm legătura"],
    ["contactDetailsLoding", "Se încarcă"],
    ["contactDetailsSuccess", "Mesajul tău a fost trimis cu success. Mulțumesc!"],
    ["contactDetailsError", "Mesajul tău nu a putut fi trimis. Te rog să-mi trimiți un email la adresa menționată!"],
    ["contactDetailsMessage", `Ai nevoie de mai multe detalii despre serviciile oferite de mine? Sau pur și simplu ai alte curiozități legate de ceea ce fac?
                               Indiferent de întrebările tale, lasă-mi un mesaj sau un email și o să te contactez cât de repede`],
    ["contactDetailsSocials", "Mă poți găsi și pe"],

    //footer
    ["footerRights", "Toate drepturile rezervate"],
    ["footerDesign", "Design realizat de"]
]);