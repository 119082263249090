import React, { FC, useEffect, useRef } from 'react';
import './Hero.css';
import Typed from "typed.js";
import { TranslationService } from '../../services/translation.service';
import { useLocation } from 'react-router-dom';
import { LANGUAGES, LANGUAGES_ROUTES } from '../../data/languages';

interface HeroProps { language: string; }

const Hero: FC<HeroProps> = (props) => {
  const el = useRef<HTMLSpanElement>(null);

  const location = useLocation();

  useEffect(() => {
    const descriptions = location.pathname === LANGUAGES_ROUTES.RO ?
                         getDescriptions(LANGUAGES.RO) : 
                         getDescriptions(LANGUAGES.EN);

    const typed = new Typed(el.current as HTMLSpanElement, {
      strings: descriptions,
      loop: true,
      typeSpeed: 100,
      backSpeed: 50,
      backDelay: 2000
    });

    return () => {
      typed.destroy();
    };
  }, [location])
  
  const getDescriptions = (language: string): string[] => { 
    const descriptions = [
      TranslationService.translate("homeDescriptionDev", language),
      TranslationService.translate("homeDescriptionEng", language)
    ];

    return descriptions;
  }

  return (
    <div id="home" className="hero route bg-image">
      <div className="overlay-itro"></div>
      <div className="hero-content display-table">
        <div className="table-cell">
          <div className="container">
            <h1 className="hero-title mb-4">{TranslationService.translate("homeTitle", props.language)}</h1>
            <p className="hero-subtitle">
              <span ref={el} className="typed"></span>
            </p>
            <p className="pt-3">
              <a className="btn btn-primary btn js-scroll px-4" href="#about" role="button">{TranslationService.translate("homeButton", props.language)}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
