import { Project } from "../models/portofolio-models";

export const myProjects: Project[] = [
    {
        id: 1,
        iamges: ["crr-1.jpg", "crr-2.jpg", "crr-3.jpg", "crr-4.jpg", "crr-5.jpg"],
        title: "projectTitleCrr",
        category: "projectCategoryCrr",
        techs: "projectStackCrr",
        link: "https://github.com/marian-salvan/RCInventoryManagement",
        date: new Date(),
        company: "projectCompanyCrr",
        description: "projectDescriptionCrr"
    },
    {
        id: 2,
        iamges: ["work-1.png"],
        title: "projectTitleLoadRunner",
        category: "projectCategoryLoadRunner",
        techs: "projectStackLoadRunner",
        link: "https://www.microfocus.com/en-us/products/loadrunner-enterprise/overview",
        date: new Date(),
        company: "projectCompanyMf",
        description: "projectDescriptionLoadRunner"
    },
    {
        id: 3,
        iamges: ["work-2.jpg"],
        title: "projectTitleInreach",
        category: "projectCategoryInreach",
        techs: "projectStackInreach",
        link: "https://discover.garmin.com/en-US/inreach/personal/",
        date: new Date(),
        company: "projectCompanyGarmin",
        description: "projectDescriptionInreach"
    },
    {
        id: 4,
        iamges: ["work-3.jpg"],
        title: "projectTitleGepSmart",
        category: "projectCategoryGepSmart",
        techs: "projectStackGepSmart",
        link: "https://www.gep.com/software/gep-smart",
        date: new Date(),
        company: "projectCompanyGep",
        description: "projectDescriptionGepSmart"
    },
];