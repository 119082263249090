import { ContactModel } from "../models/contact-form.models";

export abstract class EmailService {
    public static sendEmail = async (contactModel: ContactModel) => {
        const response = await fetch('https://formsubmit.co/ajax/marian.f.salvan@gmail.com', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                name: contactModel.name,
                message: `From: ${contactModel.email}\n${contactModel.message}`
            })
        });

        return response.json();
    }
}