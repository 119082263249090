import React, { FC, useEffect, useState } from 'react';
import './Contact.css';
import { ContactModel, FormState } from '../../models/contact-form.models';
import { EmailService } from '../../services/email.service';
import { TranslationService } from '../../services/translation.service';

interface ContactProps { language: string; }

const Contact: FC<ContactProps> = (props) => {
  const honeyInputValue = "check";
  const [contactModel, setContactModel] = useState<ContactModel>({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [formState, setFormState] = useState<FormState>({
    submitted: false,
    success: false,
    loading: false
  });

  useEffect(() => {
    const element = document.getElementById("js-input");

    if (element) {
      element.style.display = "none";
    }
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value as string;

    setContactModel({
      ...contactModel,
      [name]: value
    });
  }

  const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContactModel({
      ...contactModel,
      message: event.target.value as string,
    });
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const honeyCss = document.querySelector("#empty-input input[name='honey-css']") as HTMLInputElement;
    const honeyJs = document.querySelector("#js-input input[name='honey-js']") as HTMLInputElement;

    if (honeyCss.value === "" && honeyJs.value === honeyInputValue) {
      setFormState({ success: false, submitted: true, loading: true });

      try {
        await EmailService.sendEmail(contactModel);

        setFormState({ submitted: true, success: true, loading: false });
      } catch (error) {
        setFormState({ submitted: true, success: false, loading: false });
      }
    }
  }

  return (
    <section id="contact" className="paralax-mf footer-paralax bg-image sect-mt4 route">
      <div className="overlay-mf"></div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="contact-mf">
              <div id="contact" className="box-shadow-full">
                <div className="row">
                  <div className="col-md-6">
                    <div className="title-box-2">
                      <h5 className="title-left">
                        {TranslationService.translate("contactFormTitle", props.language)}
                      </h5>
                    </div>
                    <div>
                      <form onSubmit={handleSubmit} className="php-email-form">
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <div className="form-group">
                              <input onChange={handleInputChange} type="text" className="form-control"
                                name="name" id="name" placeholder={TranslationService.translate("contactFormName", props.language)} required disabled={formState.submitted} />
                            </div>
                          </div>
                          <div className="col-md-12 mb-3">
                            <div className="form-group">
                              <input onChange={handleInputChange} type="email" className="form-control"
                                name="email" id="email" placeholder={TranslationService.translate("contactFormEmail", props.language)} required disabled={formState.submitted} />
                            </div>
                          </div>
                          <div className="col-md-12 mb-3">
                            <div className="form-group">
                              <input onChange={handleInputChange} type="text" className="form-control"
                                name="subject" id="subject" placeholder={TranslationService.translate("contactFormSubject", props.language)} required disabled={formState.submitted} />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <textarea onChange={handleTextAreaChange} className="form-control"
                                name="message" rows={5} placeholder={TranslationService.translate("contactFormMessage", props.language)} required disabled={formState.submitted}></textarea>
                            </div>
                          </div>
                          <div id="empty-input">
                            <input type="text" name="honey-css" defaultValue={""} />
                          </div>
                          <div id="js-input">
                            <input type="text" name="honey-js" defaultValue={honeyInputValue} />
                          </div>
                          <div className="col-md-12 text-center my-3">
                            {
                              formState.submitted && formState.loading && <div className="loading">{TranslationService.translate("contactDetailsLoding", props.language)}</div>
                            }
                            {
                              formState.submitted && formState.success && !formState.loading && <div className="sent-message">{TranslationService.translate("contactDetailsSuccess", props.language)}</div>
                            }
                            {
                              formState.submitted && !formState.success && <div className="error-message">{TranslationService.translate("contactDetailsError", props.language)}</div>
                            }
                          </div>
                          <div className="col-md-12 text-center">
                            <button type="submit" className="button button-a button-big button-rouded" disabled={formState.submitted}>{TranslationService.translate("contactFormButton", props.language)}</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="title-box-2 pt-4 pt-md-0">
                      <h5 className="title-left">
                        {TranslationService.translate("contactDetailsTitle", props.language)}
                      </h5>
                    </div>
                    <div className="more-info">
                      <p className="lead">
                        {TranslationService.translate("contactDetailsMessage", props.language)}
                      </p>
                      <ul className="list-ico">
                        <li><span className="bi bi-envelope"></span>marian.f.salvan@gmail.com</li>
                      </ul>
                    </div>
                    <div className="socials">
                      <p className="lead">
                        {TranslationService.translate("contactDetailsSocials", props.language)}
                      </p>
                      <ul>
                        <li><a href="https://www.linkedin.com/in/marian-salvan-657896127/"><span className="ico-circle" title="LinkedIn"><i className="bi bi-linkedin"></i></span></a></li>
                        <li><a href="https://github.com/marian-salvan"><span className="ico-circle" title="GitHub"><i className="bi bi-github"></i></span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
