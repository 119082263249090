import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { TranslationService } from '../../services/translation.service';
import './PortofolioHeader.css';

interface PortofolioHeaderProps { language: string; }

const PortofolioHeader: FC<PortofolioHeaderProps> = (props) => {

  return (
    <div className="hero hero-single route bg-image">
      <div className="overlay-mf"></div>
      <div className="hero-content display-table">
        <div className="table-cell">
          <div className="container">
            <h2 className="hero-title mb-4">{TranslationService.translate("projectDetailsTitle", props.language)}</h2>
            <ol className="breadcrumb d-flex justify-content-center">
              <li className="breadcrumb-item">
                <Link to={`/${props.language.toLocaleLowerCase()}`}>
                  {TranslationService.translate("home", props.language)}
                </Link>
              </li>
              <li className="breadcrumb-item active">{TranslationService.translate("projectDetailsTitle", props.language)}</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortofolioHeader;
