import { PortofolioEntryProps } from "../components/PortofolioEntry/PortofolioEntry.props";
import { Project } from "../models/portofolio-models";
import { myProjects } from "./portofolio-details";

export const PortofolioEntries: PortofolioEntryProps[] = myProjects.map((p: Project) => {
    const result: PortofolioEntryProps =
    {
        id: p.id,
        imageName: p.iamges[0],
        title: p.title,
        category: p.category,
        date: p.date,
        language: ""
    }

    return result;
});