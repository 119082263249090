import React, { FC } from 'react';
import { TranslationService } from '../../services/translation.service';
import './About.css';

interface AboutProps { language: string; }

const About: FC<AboutProps> = (props) => {
  return (
    <section id="about" className="about-mf sect-pt4 route">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="box-shadow-full">
              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-sm-6 col-md-5">
                      <div className="about-img">
                        <img src="/images/me.jfif" className="img-fluid rounded b-shadow-a" alt="" />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-7">
                      <div className="about-info">
                        <p><span className="title-s">{TranslationService.translate("aboutName", props.language)}: </span> <span>Marian Salvan</span></p>
                        <p><span className="title-s">{TranslationService.translate("aboutProfie", props.language)}: </span> <span>{TranslationService.translate("aboutJob", props.language)}</span></p>
                        <p><span className="title-s">CV: </span> <a href='/files/cv.pdf#toolbar=0'>{TranslationService.translate("aboutCV", props.language)}</a></p>
                      </div>
                    </div>
                  </div>
                  <div className="skill-mf">
                    <p className="title-s">{TranslationService.translate("aboutTech", props.language)}</p>
                    <div className="row tech">
                      <div className="col-md-4 col-sm-6 col-xs-6">
                        <i className="devicon-react-original-wordmark colored" title="React"></i>
                        <i className="devicon-redux-original colored" title="Redux"></i>
                        <i className="devicon-angularjs-plain colored" title="Angular"></i>
                      </div>
                      <div className="col-md-4 col-sm-6 col-xs-6">
                        <i className="devicon-typescript-plain colored" title="TypeScript"></i>
                        <i className="devicon-javascript-plain colored" title="JavaScript"></i>
                        <i className="devicon-bootstrap-plain-wordmark colored" title="Boostrap"></i>
                      </div>

                    </div>
                    <div className="row tech">
                      <div className="col-md-4 col-sm-6 col-xs-6">
                        <i className="devicon-materialui-plain colored" title="Material UI"></i>
                        <i className="devicon-html5-plain-wordmark colored" title="HTML5"></i>
                        <i className="devicon-css3-plain-wordmark colored" title="CSS3"></i>
                      </div>
                      <div className="col-md-4 col-sm-6 col-xs-6">
                        <i className="devicon-dotnetcore-plain colored" title=".NET Core"></i>
                        <i className="devicon-csharp-plain colored" title="C#"></i>
                        <i className="devicon-azure-plain colored" title="Azure"></i>
                      </div>
                    </div>
                    <div className="row tech">
                      <div className="col-md-4 col-sm-6 col-xs-6">
                        <i className="devicon-microsoftsqlserver-plain-wordmark colored" title="Microsoft SQL Server"></i>
                        <i className="devicon-postgresql-plain-wordmark colored" title="Postgre SQL"></i>
                        <i className="devicon-mongodb-plain-wordmark colored" title="Mongo DB"></i>
                      </div>
                      <div className="col-md-4 col-sm-6 col-xs-6">
                        <i className="devicon-git-plain-wordmark colored" title="GIT"></i>
                        <i className="devicon-docker-plain-wordmark colored" title="Docker"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="about-me pt-4 pt-md-0">
                    <div className="title-box-2">
                      <h5 className="title-left">{TranslationService.translate("aboutTitle", props.language)}</h5>
                    </div>
                    <p className="lead">
                      {TranslationService.translate("aboutDescriptionP1", props.language)}
                    </p>
                    <p className="lead">
                      {TranslationService.translate("aboutDescriptionP2", props.language)}
                    </p>
                    <p className="lead">
                      {TranslationService.translate("aboutDescriptionP3", props.language)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
