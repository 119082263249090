import React, { FC, useEffect, useState } from 'react';
import './LandingPage.css';
import About from '../../components/About/About';
import Hero from '../../components/Hero/Hero';
import Services from '../../components/Services/Services';
import Work from '../../components/Work/Work';
import Contact from '../../components/Contact/Contact';
import Header from '../../components/Header/Header';
import { MainRoutes } from '../../data/routes';
import { useLocation } from 'react-router-dom';
import { LANGUAGES, LANGUAGES_ROUTES } from '../../data/languages';

interface LandingPageProps { }

const LandingPage: FC<LandingPageProps> = () => {
  const location = useLocation();
  const [currentLanguage, setCurrentLanguage] = useState<string>(LANGUAGES.EN)
  
  useEffect(() => {
    if (location) {
      switch (location.pathname) {      
        case LANGUAGES_ROUTES.EN: setCurrentLanguage(LANGUAGES.EN); break;
        case LANGUAGES_ROUTES.RO: setCurrentLanguage(LANGUAGES.RO); break;
        default: setCurrentLanguage(LANGUAGES.EN);
      }
    }
  }, [location])
  
  return (
    <React.Fragment>
      <Header options={MainRoutes} showOptions={true} language={currentLanguage} />
      <Hero language={currentLanguage} />
      <About language={currentLanguage} />
      <Services language={currentLanguage} />
      <Work language={currentLanguage} />
      <Contact language={currentLanguage} />
    </React.Fragment >
  );
}

export default LandingPage;