import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import PortofolioDetails from './pages/PortofolioDetails/PortofolioDetails';
import LandingPage from './pages/LandingPage/LandingPage';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path='/en' element={<LandingPage />} />
          <Route path='/en/portofolio-details/:id' element={<PortofolioDetails />} />
          <Route path='/ro' element={<LandingPage />} />
          <Route path='/ro/portofolio-details/:id' element={<PortofolioDetails />} />
          <Route path="*" element={<Navigate to="/en" />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
