import { FC } from 'react';
import { Link } from 'react-router-dom';
import { TranslationService } from '../../services/translation.service';
import './PortofolioEntry.css';
import { PortofolioEntryProps } from './PortofolioEntry.props';

const PortofolioEntry: FC<PortofolioEntryProps> = (props) => {
  const { id, imageName, title, category } = props;
  const url = `portofolio-details/${id}`;

  // const formatDate = (date: Date): string => {
  //   return date.toLocaleDateString("en-GB");
  // }

  return (
    <div className="work-box">
      <div className="portfolio-lightbox">
        <div className="work-img">
          <img src={`/images/${imageName}`} alt="" className="img-fluid" />
        </div>
      </div>
      <div className="work-content">
        <div className="row">
          <div className="col-sm-8">
            <h2 className="w-title">{TranslationService.translate(title, props.language)}</h2>
            <div className="w-more">
              <span className="w-ctegory">{TranslationService.translate(category, props.language)}</span>
              {/* / <span className="w-date">{formatDate(date)}</span> */}
            </div>
          </div>
          <div className="col-sm-4">
            <div className="w-like">
              <Link to={url}>
                <span className="bi bi-plus-circle"></span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortofolioEntry;
