export const engDict = new Map<string, string>([
    //header menu
    ["home", "Home"],
    ["about", "About"],
    ["services", "Services"],
    ["work", "Work"],
    ["blog", "Blog"],
    ["contact", "Contact"],
    ["languageToolTip", "Change to Romanian"],
    ["homeTitle", "I am Marian Salvan"],
    ["homeDescriptionDev", "Web Developer"],
    ["homeDescriptionEng", "Software Engineer"],
    ["homeButton", "Find out more"],

    //about
    ["aboutName", "Name"],
    ["aboutProfie", "Profile"],
    ["aboutJob", "Full Stack Web Developer"],
    ["aboutCV", "See the CV"],
    ["aboutTech", "Some technologies I worked with"],
    ["aboutTitle", "About me"],
    ["aboutDescriptionP1", `I am an ambitious web developer with 5+ years experience in web development and a Bachelor's 
                            degree in Computer Engineering. Although I work as a web developer, I am also familiar with deep learning and AI, 
                            having a Master's Degree in this subject.`],
    ["aboutDescriptionP2", `I have worked on various enterprise projects at some big industry names like Micro Focus, Garmin or GEP. 
                            My area of expertise is building end to end solutions containing RESTfull APIs, user interfaces and databases.
                            I am also familiar with deployment process of these solutions into the cloud.`],
    ["aboutDescriptionP3", `Nowadays, as we live in the middle of a technological revolution, it can be overwhelming for people who need an 
                            quality web solution for their idea to decide how to start developing it because of they have such a wide array of choices. 
                            I strongly believe that every great idea, needs an equally great website and that 
                            is why my mission is to help people figure out what they need and to work together with 
                            them towards the development of a cutting edge and quality solution.`],

    //services
    ["servicesDescription", "Some of the services that I offer"],

    ["consultingTitle", "Consulting"],
    ["consultingDesciption", `I help people figure out what they need and how their ideas can be implemented from
                              a technical stand point`],

    ["frontEndTitle", "Development Of New Web Solutions"],
    ["frontEndDescription", "I develop interactive web sites from scratch using the latest technologies for any type of business"],

    ["backEndTitle", "Enhancement of Existing Web Solutions"],
    ["backEndDescription", `I develop new features to existing client products`],

    ["responsiveDesignTitle", "Responsive Design"],
    ["responsiveDesignDescription", `I develop web sites that are responsive and can be used from multiple
                            device types and resolutions`],

    ["deploymentTitle", "Deployments"],
    ["deploymentDescription", `I deploy the the solutions and make them available on the internet`],

    ["mantainanceTitle", "Mantainance"],
    ["mantainanceDescription", "I mantain, update and fix any issues that might appear in the product"],

    //work
    ["portofolioTitle", "Projects"],
    ["portofolioDescription", "Projects that I worked on"],
    ["projectDetailsTitle", "Project Details"],

    //portofolio details
    ["detailsProjectInfo", "Project information"],
    ["detailsProjectName", "Project name"],
    ["detailsCategory", "Category"],
    ["detailsCompany", "Organization"],
    ["detailsProjectDescription", "Project description"],
    ["techsUsed", "Tech stack"],

    ["projectTitleLoadRunner", "LoadRunner Enterprise"],
    ["projectCategoryLoadRunner", "Corporate Project"],
    ["projectDescriptionLoadRunner", `LoadRunner Enterprise is the leading product when it comes to the performance testing.
                                    It is used by major companies as their main testing tool for improving their applications performance.
                                    For this product I have worked on various UI enhancements and cloud integrations, while also being part in taking of 
                                    some architecture decisions and in the effort of refactoring the monolith project to a micro services 
                                    centered architecture`],
    ["projectStackLoadRunner", ".NET core, Angular, jQuery, Microsoft SQL, Entity Framework Core, AWS and Azure"],
    ["projectCompanyMf", "Micro Focus"],

    ["projectTitleInreach", "inReach Devices Satelite Communication"],
    ["projectCategoryInreach", "Corporate Project"],
    ["projectDescriptionInreach", `inReach devices are phone like devices that are communicating though satellite. They can be used in areas
                                where there doesn't exist any traditional signal coverage, having functionalities for sending and receiving messages,
                                GPS maps or triggering SOS in case of an emergencies. I have worked on the sending and receiving messages functionality,
                                where I took part in the development of a micro service that interacted with Iridium satellite network`],
    ["projectStackInreach", ".NET Core, Service Fabric, Terraform and Azure. I have also briefly used Blazor for the implementation of an internal admin website"],
    ["projectCompanyGarmin", "Garmin"],

    ["projectTitleGepSmart", "GEP Smart"],
    ["projectCategoryGepSmart", "Corporate Project"],
    ["projectDescriptionGepSmart", `GEP Smart is a platform which brings together all the logistical operations of a modern supply chains from
                                the supplier to the client. The platform handles the processing of the entire document base needed for a procurement process.
                                I was part of the engineering team that implemented the new iteration of the platform that is based the Low Code concept,
                                using microservices and micro frontends`],
    ["projectStackGepSmart", "Angular, .NET core, MongoDB, Cypress and Azure"],
    ["projectCompanyGep", "GEP"],

    ["projectTitleCrr", "Donations Management App For Romanian Red Cross (Cluj Branch)"],
    ["projectCategoryCrr", "Personal Project"],
    ["projectDescriptionCrr", `I volunteered to develop this project for Romanian Red Cross (Cluj Branch) in order to digitalize their inventory 
                               management process. Initially, it was used only for the management of donations that went to Ukraine, but it grew 
                               to a more generic application which now can be used in many scenarios. I still manage and update this application periodically`],
    ["projectStackCrr", "React + Redux, Boostrap and Firebase"],
    ["projectCompanyCrr", "Romanian Red Cross (Cluj Branch)"],

    //contacts
    ["contactFormTitle", "Send me a message"],
    ["contactFormName", "Your Name"],
    ["contactFormEmail", "Your Email"],
    ["contactFormSubject", "Subject"],
    ["contactFormMessage", "Message"],
    ["contactFormButton", "Send Message"],
    ["contactDetailsTitle", "Get in Touch"],
    ["contactDetailsLoding", "Loading"],
    ["contactDetailsSuccess", "Your message has been sent. Thank you!"],
    ["contactDetailsError", "Your message could not be sent. Please send me an email to the provided email address!"],
    ["contactDetailsMessage", `Do you need more details about my services? Or do simply have other curiosities about what I do? 
          Whatever you want to ask don't hesitate to drop me a message or an email! I will get back to you as soon as possible`],
    ["contactDetailsSocials", "You can also find me on"],

    //footer
    ["footerRights", " All Rights Reserved"],
    ["footerDesign", "Designed by"],
]);