import { LANGUAGES } from "../data/languages";
import { TranslationModel } from "../models/translations.models";
import { engDict } from "../translations/translations.en";
import { roDict } from "../translations/translations.ro";

export abstract class TranslationService {
    private static readonly _translationModel: TranslationModel = {
        roDict: roDict,
        engDict: engDict
    }

    public static translate = (key: string, language: string = LANGUAGES.EN): string => {
        switch (language) {
            case LANGUAGES.EN:
                return this._translationModel.engDict.get(key) as string;
            case LANGUAGES.RO:
                return this._translationModel.roDict.get(key) as string;
            default:
                return this._translationModel.engDict.get(key) as string;
        }
    }
}