import React, { FC } from 'react';
import { TranslationService } from '../../services/translation.service';
import './Service.css';
import { ServiceProps } from './Service.props';

const Service: FC<ServiceProps> = (props) => {

  const { title, description, icon } = props;

  return (
    <div className="service-box">
      <div className="service-ico">
        <span className="ico-circle"><i className={`bi ${icon}`}></i></span>
      </div>
      <div className="service-content">
        <h2 className="s-title">{TranslationService.translate(title, props.language)}</h2>
        <p className="s-description text-center">{TranslationService.translate(description, props.language)}</p>
      </div>
    </div>
  );
};

export default Service;
