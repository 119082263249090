import React, { FC, useEffect, useState } from 'react';import { useLocation } from 'react-router-dom';
import { LANGUAGES, LANGUAGES_ROUTES } from '../../data/languages';
 import { SITE_CONSTANTS } from '../../data/site.constants';
import { TranslationService } from '../../services/translation.service';
import './Footer.css';

interface FooterProps { }

const Footer: FC<FooterProps> = () => {
  const defaultBackToTopclassName = "back-to-top d-flex align-items-center justify-content-center";

  const [backToTopclassName, setBackToTopclassName] = useState<string>(defaultBackToTopclassName);
  const [displayPreloader, setDisplayPreloader] = useState<boolean>(true);

  const location = useLocation();
  const [currentLanguage, setCurrentLanguage] = useState<string>(LANGUAGES.EN)
  
  useEffect(() => {
    if (location) {
      if (location.pathname.includes(LANGUAGES_ROUTES.EN)) {
        setCurrentLanguage(LANGUAGES.EN);
      } else if (location.pathname.includes(LANGUAGES_ROUTES.RO)) {
        setCurrentLanguage(LANGUAGES.RO);
      }
    }
  }, [location])
  
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setBackToTopclassName(`active ${defaultBackToTopclassName}`);
      } else {
        setBackToTopclassName(defaultBackToTopclassName);
      }
    }

    setDisplayPreloader(false);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <React.Fragment>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="copyright-box">
                <p className="copyright">&copy; Copyright <strong>{SITE_CONSTANTS.SITE_NAME}</strong> {TranslationService.translate("footerRights", currentLanguage)}</p>
                <div className="credits">
                  {TranslationService.translate("footerDesign", currentLanguage)} <a href="https://bootstrapmade.com/">BootstrapMade</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {
        displayPreloader && (
          <div id="preloader"></div>
        )
      }
      <a href="#home" className={backToTopclassName}>
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </React.Fragment>
  );
};

export default Footer;
