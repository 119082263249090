import { Service } from "../models/services.models";

export const MyServices: Service[] = [
    {
        title: "consultingTitle",
        description: "consultingDesciption",
        icon: "bi-people",
    },
    {
        title: "frontEndTitle",
        description: "frontEndDescription",
        icon: " bi-display"
    },
    {
        title: "backEndTitle",
        description: "backEndDescription",
        icon: "bi-puzzle"
    },
    {
        title: "responsiveDesignTitle",
        description: "responsiveDesignDescription",
        icon: "bi-phone"
    },
    {
        title: "deploymentTitle",
        description: "deploymentDescription",
        icon: "bi-globe"
    },
    {
        title: "mantainanceTitle",
        description: "mantainanceDescription",
        icon: "bi-tools"
    }
];