import React, { FC } from 'react';
import Service from '../Service/Service';
import { MyServices } from '../../data/services';
import './Services.css';
import { TranslationService } from '../../services/translation.service';

interface ServicesProps { language: string; }

const Services: FC<ServicesProps> = (props) => {
  return (
    <section id="services" className="services-mf pt-5 route">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="title-box text-center">
              <h3 className="title-a">
                {TranslationService.translate("services", props.language)}
              </h3>
              <p className="subtitle-a">
                {TranslationService.translate("servicesDescription", props.language)}
              </p>
              <div className="line-mf"></div>
            </div>
          </div>
        </div>
        <div className="row">
          {MyServices.map(service => (
            <div className="col-md-4" key={service.title}>
              <Service title={service.title} description={service.description} icon={service.icon} language={props.language} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
