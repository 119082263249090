import { FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LANGUAGES, LANGUAGES_ROUTES } from '../../data/languages';
import { SITE_CONSTANTS } from '../../data/site.constants';
import { TranslationService } from '../../services/translation.service';
import './Header.css';
import { HeaderProps } from './Header.props';

const Header: FC<HeaderProps> = (props) => {
  const [first, ...others] = props.options;
  const currentLanguage = props.language;

  const [isMobile, setMobile] = useState<boolean>(false);
  const [isScrolled, setScrolled] = useState<boolean>(false);

  let navigate = useNavigate();

  const toggleNavbar = () => {
    setMobile(!isMobile);
  }

  const onOptionClicked = () => {
    if (isMobile) {
      setMobile(false);
    }
  }

  const onLanguageSelect = (route: string) => { 
    navigate(route);
    document.location.reload();
  }

  useEffect(() => {
    const navbarlinks = document.querySelectorAll('#navbar .scrollto');

    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }

      const position = window.scrollY + 200
      navbarlinks.forEach((navbarlink: Element) => {
        const hrefAttribuute = navbarlink.getAttribute('href');

        if (!hrefAttribuute) return;

        const sectionId = hrefAttribuute.substring(1);
        const section = document.getElementById(sectionId);

        if (!section) return;

        if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
          navbarlink.classList.add('active');
        } else {
          navbarlink.classList.remove('active');
        }
      })
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header id="header" className={!isScrolled ? "fixed-top" : "fixed-top header-scrolled"}>
      <div className="container d-flex align-items-center justify-content-between">
        <div>
          {
            props.showOptions ?
              <h1 className="logo"><a href="#home">{SITE_CONSTANTS.SITE_NAME}</a></h1> :
              <Link className="logo" to="/">{SITE_CONSTANTS.SITE_NAME}</Link>
          }
          {
            currentLanguage === LANGUAGES.RO &&
            <div className="language" onClick={() => onLanguageSelect(LANGUAGES_ROUTES.EN)} title={TranslationService.translate("languageToolTip", currentLanguage)}>
              <span className="flag-icon flag-icon-ro"></span>
              <span className="name">RO</span>
            </div>
          }
          {
            currentLanguage === LANGUAGES.EN &&
            <div className="language" onClick={() => onLanguageSelect(LANGUAGES_ROUTES.RO)} title={TranslationService.translate("languageToolTip", currentLanguage)}>
              <span className="flag-icon flag-icon-us"></span>
              <span className="name">EN</span>
            </div>
          }
        </div> {
          props.showOptions &&
          <nav id="navbar" className={!isMobile ? "navbar-desktop" : "navbar-desktop navbar-mobile"}>
            <ul>
              <li><a className="nav-link scrollto active" href={`#${first}`} onClick={onOptionClicked}>{TranslationService.translate(first, currentLanguage)}</a></li>
              {others.map(route => (
                <li key={route}><a className="nav-link scrollto" href={`#${route}`} onClick={onOptionClicked}>{TranslationService.translate(route, currentLanguage)}</a></li>
              ))}
            </ul>
            <i className={!isMobile ? "bi bi-list mobile-nav-toggle" : "bi bi-x mobile-nav-toggle"} onClick={toggleNavbar}></i>
          </nav>
        }
      </div>
    </header>
  );
};

export default Header;
