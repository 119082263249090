import React, { FC } from 'react';
import PortofolioEntry from '../PortofolioEntry/PortofolioEntry';
import { PortofolioEntries } from '../../data/portofolio-entries';
import './Work.css';
import { TranslationService } from '../../services/translation.service';

interface WorkProps { language: string; }

const Work: FC<WorkProps> = (props) => {
  return (
    <section id="work" className="portfolio-mf sect-pt4 route">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="title-box text-center">
              <h3 className="title-a">
                {TranslationService.translate("portofolioTitle", props.language)}
              </h3>
              <p className="subtitle-a">
                {TranslationService.translate("portofolioDescription", props.language)}
              </p>
              <div className="line-mf"></div>
            </div>
          </div>
        </div>
        <div className="row">
          {PortofolioEntries.map(entry => (
            <div className="col-md-4" key={entry.title}>
              <PortofolioEntry id={entry.id} imageName={entry.imageName}
                title={entry.title} category={entry.category} date={entry.date}
                language={props.language} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Work;
