import React, { FC, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import './PortofolioDetails.css';
import Swiper, { Navigation, Pagination } from 'swiper';
import "swiper/css";
import "swiper/css/pagination";
import Header from '../../components/Header/Header';
import { MainRoutes } from '../../data/routes';
import PortofolioHeader from '../../components/PortofolioHeader/PortofolioHeader';
import { Project } from '../../models/portofolio-models';
import { ProjectsService } from '../../services/projects.service';
import { TranslationService } from '../../services/translation.service';
import { LANGUAGES, LANGUAGES_ROUTES } from '../../data/languages';

interface PortofolioDetailsProps { }

const PortofolioDetails: FC<PortofolioDetailsProps> = () => {
  const { id } = useParams();
  const [selectedProject, setSelectedProject] = useState<Project | undefined>(undefined);

  const location = useLocation();
  const [currentLanguage, setCurrentLanguage] = useState<string>(LANGUAGES.EN)
  
  useEffect(() => {
    if (location) {
      if (location.pathname.includes(LANGUAGES_ROUTES.EN)) {
        setCurrentLanguage(LANGUAGES.EN);
      } else if (location.pathname.includes(LANGUAGES_ROUTES.RO)) {
        setCurrentLanguage(LANGUAGES.RO);
      }
    }
  }, [location])
  
  useEffect(() => {
    setSelectedProject(ProjectsService.getProjectById(parseInt(id as string)));

    Swiper.use([Navigation, Pagination]);

    new Swiper('.portfolio-details-slider', {
      speed: 400,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    });
  }, [id]);

  return (
    <React.Fragment>
      <Header options={MainRoutes} showOptions={false} language={currentLanguage} />
      <PortofolioHeader language={currentLanguage} />

      <main id="main">
        <section id="portfolio-details" className="portfolio-details">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-8">
                <div className="portfolio-details-slider swiper">
                  <div className="swiper-wrapper align-items-center">
                    {selectedProject?.iamges.map(image => (
                      <div className="swiper-slide">
                        <img src={"/images/" + image} alt="" />
                      </div>
                    ))}
                  </div>
                  <div className="swiper-pagination"></div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="portfolio-info">
                  <h3>{TranslationService.translate("detailsProjectInfo", currentLanguage)}</h3>
                  <ul>
                    <li>
                      <strong>{TranslationService.translate("detailsProjectName", currentLanguage)}</strong>: <a href={selectedProject?.link}>{TranslationService.translate(selectedProject?.title as string, currentLanguage)}</a>
                    </li>
                    <li>
                      <strong>{TranslationService.translate("detailsCategory", currentLanguage)}</strong>: {TranslationService.translate(selectedProject?.category as string, currentLanguage)}
                    </li>
                    <li><strong>{TranslationService.translate("detailsCompany", currentLanguage)}</strong>: {TranslationService.translate(selectedProject?.company as string, currentLanguage)} </li>
                  </ul>
                </div>
                <div className="portfolio-description">
                  <h2>{TranslationService.translate("detailsProjectDescription", currentLanguage)}</h2>
                  <p>
                    {TranslationService.translate(selectedProject?.description as string, currentLanguage)}
                  </p>
                  <h6>{TranslationService.translate("techsUsed", currentLanguage)} </h6>
                  <p>
                  {TranslationService.translate(selectedProject?.techs as string, currentLanguage)}
                  </p>
                </div>
              </div>

            </div>

          </div>
        </section>
      </main >
    </React.Fragment>
  );
};

export default PortofolioDetails;
